<template>
    <div class='authCover' v-if="isShowAuth">
        <div class="authBox">
            <i class="el-icon-close closebtn" @click="closeFun"></i>
            <p class="dltit">实名认证</p>
            <div class="desc">根据法律法规的要求，在实名认证的过程中，我们和提供认证服务的第三方机构需要您提供真实姓名、身份证号码。这些信息是个人敏感信息，平台会对信息进行严格保密，未经您明示授权，不会用作其他目的。
            </div>
            <form class="formBox" ref="form" :model="form">
                <div class="inputBox name">
                    <input placeholder="请输入真实姓名" v-model="form.name" />
                </div>
                <div class="inputBox cardId">
                    <input placeholder="请输入身份证号" maxlength="18" v-model="form.cardId" />
                </div>
                <div class="loginBtn" @click="submit">立即认证</div>
                <el-checkbox v-model="isCheckArgee" class="checkBox">
                    <div class="agreement">我已同意
                        <span @click="agreeHandle('agreeView')">用户协议</span>和
                        <span @click="agreeHandle('privacyPolicy')">隐私政策</span>
                    </div>
                </el-checkbox>
            </form>
        </div>
    </div>
</template>

<script>
import store from "../store";
import { setCardId } from "@/util/api/api.js"
export default {
    props: {
        isShowAuth: {
            type: Boolean,
        },
    },
    data() {
        return {
            form: {
                name: '',
                cardId: '',
            },
            isCheckArgee: false,//协议check
        }
    },
    created() {

    },
    methods: {
        closeFun() {
            this.$emit('closeAuthHandle', false)
        },

        // 提交认证
        async submit() {
            console.log('实名认证')
            if (!this.isCheckArgee) {
                this.$message.warning("请阅读并勾选协议")
                return
            }
            try {
                let params = {
                    ...this.form,
                    cardType: 1
                }
                let res = await setCardId(params)
                if (res.resultCode == '000000') {
                    this.$message.success("实名认证成功")
                    store.dispatch('setShowAuth', false)
                }
            } catch (error) {
                console.log(error)
            }
        },

        // 跳转用户协议、隐私政策
        agreeHandle(type) {
            let routeData = this.$router.resolve(`/${type}`);
            window.open(routeData.href, '_blank');
        }
    },
}
</script>

<style lang="less">
.authCover {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    .authBox {
        width: 500px;
        height: 750px;
        background: url(../assets/imgs/wxbg.png) no-repeat;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -375px;
        margin-left: -250px;

        .closebtn {
            color: #fff;
            cursor: pointer;
            font-size: 25px;
            position: absolute;
            top: 30px;
            right: 30px;
        }

        .dltit {
            text-align: center;
            font-size: 32px;
            margin: 40px auto 46px;
            color: #fff;
        }

        .desc {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0 50px;
            margin-bottom: 48px;
        }


        .formBox {
            color: #fff;

            .checkBox {
                width: 100%;
                margin: 0px auto;
                text-align: center;

                .el-checkbox__label {
                    color: #fff;
                }

                .el-checkbox__inner {
                    background-color: #B586F2 !important;
                    border-color: #B586F2 !important;
                }

                // .el-checkbox__input{
                // 	.is-checked{
                // 		background-color:#B586F2 !important;
                // 	}
                // }
            }

            .inputBox {
                width: 400px;
                height: 64px;
                background: rgba(255, 255, 255, 0.2);
                border-radius: 54px;
                margin: 0 auto 24px;
                overflow: hidden;
                line-height: 64px;
                font-size: 24px;

                input {
                    color: #fff;
                    font-size: 24px;
                    border: none;
                    height: 23px;
                    margin: 21px 0px 0 0;
                    line-height: 23px;
                    float: left;
                    background: none;
                    outline: none;
                    padding-left: 30px;

                    &::-webkit-input-placeholder {
                        color: #947fb0;
                    }
                }
            }

            .name {
                p {
                    float: left;
                    font-size: 24px;
                    width: 103px;
                    text-align: center;
                    border-right: 1px solid #725d93;
                    height: 23px;
                    margin: 21px 00px 0 0;
                    line-height: 23px;
                }

                input {
                    width: 236px;
                }
            }

            .cardId {
                position: relative;

                p {
                    position: absolute;
                    top: 16px;
                    right: 23px;
                    width: 96px;
                    height: 32px;
                    border-radius: 16px;
                    border: 1px solid #a593be;
                    text-align: center;
                    line-height: 32px;
                    font-size: 14px;
                    cursor: pointer;
                }
            }

            .loginBtn {
                width: 400px;
                height: 64px;
                border-radius: 54px;
                margin: 0 auto 24px;
                overflow: hidden;
                line-height: 64px;

                background: linear-gradient(to right, #705DFF, #B586F2);
                font-size: 24px;
                text-align: center;
                cursor: pointer;
                margin-top: 160px;
            }

            .agreement {
                margin: 10px auto;

                span {
                    color: #FFEC10;
                    margin: 0 6px;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
