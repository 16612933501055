import CryptoJS from 'crypto-js' // AES
import forge from 'node-forge' // RSA

// 生成AES密钥
export const getmm = (num = 16) => {
  var amm = ['!', '@', '#', '$', '%', '&', '*', '(', ')', 1, 2, 3, 4, 5, 6, 7, 8, 9]
  var tmp = Math.floor(Math.random() * num)
  var s = tmp
  s = s + amm[tmp]
  for (let i = 0; i < Math.floor(num / 2) - 1; i++) {
    tmp = Math.floor(Math.random() * 26)
    s = s + String.fromCharCode(65 + tmp)
  }
  for (let i = 0; i < (num - Math.floor(num / 2) - 1); i++) {
    tmp = Math.floor(Math.random() * 26)
    s = s + String.fromCharCode(97 + tmp)
  }
  return s
}

/**
 * 数据AES加密
 * AES 加密  data：要加密解密的数据，AES_KEY：密钥，
 * @param {*} data 
 * @param {*} AES_KEY 
 * @returns 
 */
export const encrypt = (data, AES_KEY) => {
  const key = CryptoJS.enc.Utf8.parse(AES_KEY)
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  // console.log("加密数据：" + data)
  // console.log("数据加密后：" + encrypted.toString())
  return encrypted.toString()
}

/**
 * 数据AES解密
 * AES 加密  data：要加密解密的数据，AES_KEY：密钥，
 * @param {*} data 
 * @param {*} AES_KEY 
 * @returns 
 */
export const aesDecrypt = (data, AES_KEY) => {
  // console.log("---------------------开始解密AES")
  const decrypted = CryptoJS.AES.decrypt(data.toString(), CryptoJS.enc.Utf8.parse(AES_KEY), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  // console.log("数据：" + data.toString())
  // console.log("解密后：" + decrypted.toString(CryptoJS.enc.Utf8))
  return decrypted.toString(CryptoJS.enc.Utf8)
}

/**
 * RAS加密—使用服务端的公钥加密AES密钥
 * @param {*} aeskey 
 * @param {*} pubencryptKey 
 * @returns 
 */
export const pubencrypt = (aeskey, pubencryptKey) => {
  // console.log("aeskey：" + aeskey)
  // console.log("服务器公钥：" + pubencryptKey)
  // 此处的前后缀不能改变
  const publicKeyAll = '-----BEGIN PUBLIC KEY-----\n' + pubencryptKey + '\n-----END PUBLIC KEY-----'
  var publicKey = forge.pki.publicKeyFromPem(publicKeyAll)
  var buffer = forge.util.createBuffer(aeskey, 'utf8')
  var bytes = buffer.getBytes()
  // console.log("公钥加密后的AES密钥：" + a)
  return forge.util.encode64(publicKey.encrypt(bytes, 'RSAES-PKCS1-V1_5'))
}