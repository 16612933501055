var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowMengXin
    ? _c("div", { staticClass: "mengXinCover" }, [
        _c("div", { staticClass: "mengXinCom" }, [
          _c("i", {
            staticClass: "el-icon-close closebtn",
            on: { click: _vm.closeFun },
          }),
          _c("img", {
            staticClass: "imgUrl",
            attrs: { src: _vm.mengXinInfo.imgUrl, alt: "" },
          }),
          _c("div", { staticClass: "nickName d-overflow" }, [
            _vm._v(_vm._s(_vm.mengXinInfo.nickname)),
          ]),
          _c("div", { staticClass: "btn", on: { click: _vm.privateChat } }, [
            _vm._v("聊一聊（" + _vm._s(_vm.count) + "S）"),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }