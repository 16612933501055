<template>
	<div class="userCom">
		<div class="box">
			<p class="exitBtn" @click="exitHandle">退出</p>
			<img v-if="userInfo.imgUrl" :src="userInfo.imgUrl" class="userAva" />
			<img v-else src="@/assets/imgs/avatar.png" class="userAva" />
			<p class="user_name d-overflow">{{ userInfo.nickname }}</p>
			<div class="graof d-flex a-center j-sb">
				<div class="grade">
					<img :src="userInfo.levelImg" alt="">
				</div>
				<div class="information" @click="goSetup"><img src="../assets/imgs/zl.png" />资料</div>
			</div>
			<div class="upgradation" v-if="userLeveInfo">
				<p>升级还需<span>{{ userLeveInfo.needExp }}</span>贡献值</p>
				<div class="schedule">
					<p :style="{ 'width': userLeveInfo.progress }"></p>
				</div>
				<div class="scheduletxt">
					<p>{{ userLeveInfo.leftName }}</p>
					<p>{{ userLeveInfo.rightName }}</p>
				</div>
			</div>
			<ul class="species">
				<li>
					<p>{{ balance.coin || 0 }}</p>
					<div><img src="../assets/imgs/b.png" /><span>随心币</span></div>
				</li>
				<li>
					<p>{{ balance.diamond || 0 }}</p>
					<div><img src="../assets/imgs/z.png" /><span>随心钻</span></div>
				</li>
				<li>
					<div class="rechargeBtn" @click="goRecharge">充值</div>
				</li>
			</ul>
		</div>
		
	</div>
</template>

<script>
export default {
	props: {
		userInfo: {
			type: Object,
		},
		balance: {
			type: Object,
		},
		userLeveInfo: {
			type: Object
		}
	},
	data() {
		return {


		}
	},
	methods: {
		// 退出登录
		exitHandle() {
			this.$emit('exit')
		},

		// 跳转消息
		goSetup() {
			let routeData = this.$router.resolve(`/setup/${2}/${0}`);
			window.open(routeData.href, '_blank');
		},

		// 跳转充值
		goRecharge() {
			this.$router.push({ name: 'Recharge' })
		}
	}
}
</script>

<style lang="less">
.userCom {
	display: none;
	position: absolute;
	top: 80px;
	left: -104px;
	z-index: 1000;

	.box{
		width: 264px;
		height: 284px;
		background: #201A25;
		border-radius: 12px;
		border: 1px solid #3F314A;
		margin-top: 2px;
		.exitBtn {
			color: #908d92;
			font-size: 14px;
			position: absolute;
			top: 20px;
			right: 20px;
		}

		.userAva {
			width: 52px;
			height: 52px;
			border-radius: 26px;
			margin: 25px auto 4px;
			object-fit: cover;
		}

		.user_name {
			font-size: 14px;
			color: #fff;
			text-align: center;
			width: 120px;
			margin: 0 auto;
		}

		.graof {
			width: 100px;
			margin: 5px auto 15px;
			// display: flex;
			// align-items: center;
			// justify-content: space-between;

			.grade {
				width: 32px;
				height: 16px;
				line-height: 18px;
				float: left;
				margin-left: 8px;

				img {
					height: 100%;
					width: 100%;
				}
			}

			.information {
				display: flex;
				justify-content: space-between;

				img {
					width: 16px;
					height: 13px;
					margin: 3px 4px 0 0;

				}

				font-size:14px;
				color:#fff;
			}
		}

		.upgradation {
			font-size: 12px;
			color: #fff;

			p {
				span {
					color: #B586F2;
				}

				margin-bottom:12px;
			}

			.schedule {
				width: 224px;
				height: 4px;
				background: rgba(255, 255, 255, 0.1);
				border-radius: 2px;
				position: relative;
				margin: 0 auto 4px;

				p {
					width: 0;
					height: 4px;
					background: #B586F2;
					border-radius: 2px;
					position: absolute;
				}
			}

			.scheduletxt {
				width: 224px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
			}

		}

		.species {
			height: 72px;
			background: rgba(216, 216, 216, 0.1);
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			display: flex;
			justify-content: space-between;

			li {
				width: 33.33%;
				border-right: 1px solid rgba(255, 255, 255, 0.1);
				margin-top: 17px;
				height: 40px;

				p {
					text-align: center;
					font-size: 16px;
					font-weight: bold;
				}

				div {
					display: flex;
					justify-content: space-between;
					width: 68%;
					margin: 5px auto 0;

					img {
						width: 16px;
						height: 16px;
					}

					span {
						font-size: 12px;
						color: #998FA6;
					}
				}

				.rechargeBtn {
					width: 57px;
					height: 24px;
					line-height: 24px;
					text-align: center;
					margin: 8px auto 0;
					background: linear-gradient(to right, #705DFF, #B586F2);
					border-radius: 12px;
					display: block;
				}
			}
		}
	}

}
</style>
