var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "userCom" }, [
    _c("div", { staticClass: "box" }, [
      _c("p", { staticClass: "exitBtn", on: { click: _vm.exitHandle } }, [
        _vm._v("退出"),
      ]),
      _vm.userInfo.imgUrl
        ? _c("img", {
            staticClass: "userAva",
            attrs: { src: _vm.userInfo.imgUrl },
          })
        : _c("img", {
            staticClass: "userAva",
            attrs: { src: require("@/assets/imgs/avatar.png") },
          }),
      _c("p", { staticClass: "user_name d-overflow" }, [
        _vm._v(_vm._s(_vm.userInfo.nickname)),
      ]),
      _c("div", { staticClass: "graof d-flex a-center j-sb" }, [
        _c("div", { staticClass: "grade" }, [
          _c("img", { attrs: { src: _vm.userInfo.levelImg, alt: "" } }),
        ]),
        _c("div", { staticClass: "information", on: { click: _vm.goSetup } }, [
          _c("img", { attrs: { src: require("../assets/imgs/zl.png") } }),
          _vm._v("资料"),
        ]),
      ]),
      _vm.userLeveInfo
        ? _c("div", { staticClass: "upgradation" }, [
            _c("p", [
              _vm._v("升级还需"),
              _c("span", [_vm._v(_vm._s(_vm.userLeveInfo.needExp))]),
              _vm._v("贡献值"),
            ]),
            _c("div", { staticClass: "schedule" }, [
              _c("p", { style: { width: _vm.userLeveInfo.progress } }),
            ]),
            _c("div", { staticClass: "scheduletxt" }, [
              _c("p", [_vm._v(_vm._s(_vm.userLeveInfo.leftName))]),
              _c("p", [_vm._v(_vm._s(_vm.userLeveInfo.rightName))]),
            ]),
          ])
        : _vm._e(),
      _c("ul", { staticClass: "species" }, [
        _c("li", [_c("p", [_vm._v(_vm._s(_vm.balance.coin || 0))]), _vm._m(0)]),
        _c("li", [
          _c("p", [_vm._v(_vm._s(_vm.balance.diamond || 0))]),
          _vm._m(1),
        ]),
        _c("li", [
          _c(
            "div",
            { staticClass: "rechargeBtn", on: { click: _vm.goRecharge } },
            [_vm._v("充值")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", { attrs: { src: require("../assets/imgs/b.png") } }),
      _c("span", [_vm._v("随心币")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", { attrs: { src: require("../assets/imgs/z.png") } }),
      _c("span", [_vm._v("随心钻")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }