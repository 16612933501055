var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app", name: "app" } },
    [
      _vm.$route.meta.showHeader
        ? _c(
            "el-container",
            { staticClass: "bgcolor d-flex flex-column" },
            [
              _c(
                "div",
                { staticClass: "topbar", class: { topBar: !_vm.isShowTab } },
                [
                  _c(
                    "el-header",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "logo",
                          on: { click: _vm.openHomeHandle },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("./assets/imgs/logo.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _vm.isRefresh && _vm.isShowTab
                        ? _c(
                            "el-menu",
                            {
                              staticClass: "el-menu-demo",
                              attrs: {
                                "default-active": _vm.activeIndex,
                                mode: "horizontal",
                                router: "",
                              },
                            },
                            [
                              _c("el-menu-item", { attrs: { index: "/" } }, [
                                _vm._v("首页"),
                              ]),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "/hall" } },
                                [_vm._v("大厅")]
                              ),
                              _c("el-menu-item", { attrs: { index: "/god" } }, [
                                _vm._v("大神"),
                              ]),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "/shoppingMall" } },
                                [_vm._v("商城")]
                              ),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "/recharge" } },
                                [_vm._v("充值")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isRefresh && _vm.isShowTab
                        ? _c(
                            "div",
                            { staticClass: "searchCss" },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "支持房间名/ID/昵称",
                                  "prefix-icon": "el-icon-search",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchClick.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.isRefresh
                    ? _c("div", { staticClass: "nav" }, [
                        _c("ul", [
                          _vm.isShowTab
                            ? _c(
                                "li",
                                { staticClass: "roompr" },
                                [
                                  _c("div", { staticClass: "navli navli1" }),
                                  _c("p", [_vm._v("快速进房")]),
                                  _c("RoomList", {
                                    attrs: { quickRoomList: _vm.quickRoomList },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isShowTab
                            ? _c("li", { on: { click: _vm.goMessage } }, [
                                _c("div", { staticClass: "navli navli2" }),
                                _c("p", [_vm._v("消息")]),
                              ])
                            : _vm._e(),
                          _vm.isShowPrivate
                            ? _c(
                                "li",
                                {
                                  staticClass: "privateBox",
                                  on: { click: _vm.openPrivateChat },
                                },
                                [
                                  _c("div", { staticClass: "navli navlixx" }),
                                  _c("p", [_vm._v("私信")]),
                                  _vm.messageUnreadCount &&
                                  _vm.messageUnreadCount > 0
                                    ? _c("div", { staticClass: "count" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.messageUnreadCount &&
                                              _vm.messageUnreadCount > 99
                                              ? "99+"
                                              : _vm.messageUnreadCount
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          !_vm.isLogin
                            ? _c("li", { on: { click: _vm.loginFun } }, [
                                _c("div", { staticClass: "navli navli3" }),
                                _c("p", [_vm._v("登录/注册")]),
                              ])
                            : _vm.userInfo
                            ? _c(
                                "li",
                                { staticClass: "lipr" },
                                [
                                  _c("div", { staticClass: "userBox" }, [
                                    _vm.userInfo.imgUrl
                                      ? _c("img", {
                                          staticClass: "userimg",
                                          attrs: { src: _vm.userInfo.imgUrl },
                                        })
                                      : _c("img", {
                                          staticClass: "userimg",
                                          attrs: {
                                            src: require("@/assets/imgs/avatar.png"),
                                          },
                                        }),
                                    _c("div", { staticClass: "userinfo" }, [
                                      _c("div", { staticClass: "userT" }, [
                                        _c("p", [
                                          _vm._v(_vm._s(_vm.userInfo.nickname)),
                                        ]),
                                        _c("div", { staticClass: "grade" }, [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.userInfo.levelImg,
                                              alt: "",
                                            },
                                          }),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "userB" }, [
                                        _c("p", [
                                          _vm._v(
                                            "UID：" + _vm._s(_vm.userInfo.uid)
                                          ),
                                        ]),
                                        _c("i", {
                                          staticClass: "el-icon-caret-bottom",
                                        }),
                                      ]),
                                    ]),
                                  ]),
                                  _vm.userInfo
                                    ? _c("UserInfo", {
                                        attrs: {
                                          userInfo: _vm.userInfo,
                                          userLeveInfo: _vm.userLeveInfo,
                                          balance: _vm.balance,
                                        },
                                        on: { exit: _vm.logout },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.isRefresh && _vm.isShowTab ? _c("FloatingWindow") : _vm._e(),
              _vm.isRefresh
                ? _c(
                    "div",
                    { staticClass: "main d-flex flex-column flex-1" },
                    [
                      _c(
                        "keep-alive",
                        [
                          _vm.$route.meta.keepAlive
                            ? _c("router-view", { ref: "mainRef" })
                            : _vm._e(),
                        ],
                        1
                      ),
                      !_vm.$route.meta.keepAlive
                        ? _c("router-view", { ref: "mainRef" })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isShowTab
                ? _c("el-footer", [
                    _vm.isRefresh
                      ? _c("div", { staticClass: "footer" }, [
                          _c("ul", { staticClass: "webinfo" }, [
                            _c("li", [
                              _c("img", {
                                attrs: { src: require("./assets/imgs/QQ.png") },
                              }),
                              _c("p", [_vm._v("客服QQ：1058127957")]),
                              _c("p", [
                                _c("span", [_vm._v("咨询时间：7X24小时")]),
                              ]),
                            ]),
                            _c(
                              "li",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.agreeHandle("agreeView")
                                  },
                                },
                              },
                              [_c("span", [_vm._v("用户协议")])]
                            ),
                            _c(
                              "li",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.agreeHandle("privacyPolicy")
                                  },
                                },
                              },
                              [_c("span", [_vm._v("隐私政策")])]
                            ),
                            _c(
                              "li",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.agreeHandle("about")
                                  },
                                },
                              },
                              [_c("span", [_vm._v("关于我们")])]
                            ),
                          ]),
                          _c("br"),
                          _c("div", { staticClass: "comInfo" }, [
                            _c("span", [
                              _vm._v(
                                "公司地址：广西壮族自治区南宁市高新区工业园创业路东面罗赖路北面低压检验楼11层1106-3号"
                              ),
                            ]),
                            _c("span", [
                              _vm._v("公司全称：广西牧纯科技有限公司"),
                            ]),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://beian.miit.gov.cn",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("备案号：桂ICP备2023000823号-1")]
                            ),
                          ]),
                          _c("br"),
                          _c("div", { staticClass: "comInfo" }, [
                            _c("span", [
                              _vm._v("增值电信业务经营许可证：桂B2-20230344"),
                            ]),
                            _c("img", {
                              attrs: { src: require("./assets/imgs/ghs.png") },
                            }),
                            _c("span", [_vm._v("公安备案号：45010702002010")]),
                          ]),
                          _c("p", { staticClass: "copyright" }, [
                            _vm._v(
                              "Copyright 2013-2023 MCKJ Corporation, All Rights Reserved"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("LoginCom", {
        attrs: { isLogin: _vm.loginModal },
        on: { closeLogin: _vm.closeLogin, getUser: _vm.getUserinfo },
      }),
      _vm.isPrivateChat
        ? _c("PrivateChat", {
            attrs: {
              isPrivateChat: _vm.isPrivateChat,
              chatUserInfo: _vm.chatUserInfo,
            },
            on: { closePrivateChat: _vm.closePrivateChat },
          })
        : _vm._e(),
      _c("BindPhoneNumber", {
        attrs: { isWxLogin: _vm.isShowBindPhone },
        on: { closeBindPhone: _vm.closeBindPhone, getUser: _vm.getUserinfo },
      }),
      _c("AuthenticationPop", {
        attrs: { isShowAuth: _vm.isShowAuth },
        on: { closeAuthHandle: _vm.closeAuthHandle },
      }),
      _c("mengXinPop", {
        attrs: {
          isShowMengXin: _vm.isShowMengXin,
          mengXinInfo: _vm.mengXinInfo,
        },
        on: { closeMengXin: _vm.closeMengXin },
      }),
      _c("confessionWallCard"),
      !_vm.$route.meta.showHeader ? _c("router-view") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }