var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "emojiCom",
      on: {
        contextmenu: function ($event) {
          $event.preventDefault()
        },
        click: function ($event) {
          $event.stopPropagation()
          _vm.isShowDelEmoji = false
        },
      },
    },
    [
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "emojiListBox overflow-auto" }, [
          _c(
            "div",
            { staticClass: "emojiList d-flex a-center flex-wrap" },
            _vm._l(_vm.emojiList, function (item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "emojiItem",
                  style: { marginRight: (index + 1) % 5 == 0 ? "0" : "26px" },
                  on: {
                    click: function ($event) {
                      return _vm.emojiHandle(item, index)
                    },
                    contextmenu: [
                      function ($event) {
                        $event.preventDefault()
                      },
                      function ($event) {
                        return _vm.emojiRightHandle(item, index)
                      },
                    ],
                  },
                },
                [
                  _c("img", {
                    staticClass: "emojiIcon",
                    attrs: { src: item.value, alt: "" },
                  }),
                  _vm.emojiRightHandleInfo.index == index && _vm.isShowDelEmoji
                    ? _c(
                        "div",
                        {
                          staticClass: "delEmoji",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delEmojiHandle(
                                _vm.emojiRightHandleInfo.id
                              )
                            },
                          },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]),
        _c(
          "div",
          { staticClass: "emojiTabBox d-flex a-center" },
          _vm._l(_vm.tabList, function (item, index) {
            return _c(
              "div",
              {
                key: item.id,
                class: ["tab", { tabCheck: index == _vm.tabIndex }],
                on: {
                  click: function ($event) {
                    return _vm.changeTab(index)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "tabIcon",
                  attrs: {
                    src:
                      index == _vm.tabIndex
                        ? item.checkIcon
                        : item.defualutIcon,
                    alt: "",
                  },
                }),
              ]
            )
          }),
          0
        ),
      ]),
      _c("input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        ref: "fileRef",
        attrs: {
          type: "file",
          accept: "image/png, image/jpeg, image/jpg, image/gif",
        },
        on: { change: _vm.getImageInfo },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }