// SUCCESS("000000", "成功"),
// TOKEN_INVALID("000001", "Token失效"),
// USER_LOGIN_SUCCESS("000000","用户登录成功"),
// USER_NOT_FOUND("100002","用户名或密码错误"),
// USER_AUTH_ERROR("100003","权限不足"),
// ERROR_FILE_TYPE("100004","文件类型有误"),
// ERROR_FILE_UPLOAD("100005","上传文件失败，请联系管理员"),
// ERROR_READ_EXCEL("100006","导入excel出错，请检查excel内容是否正确，或联系管理员"),
// ERROR_CAPTCHA("100007","验证码错误"),
// LOGIN_ERROR("100008","登录失败"),
// SYSTEM_EXCEPTION("200001","系统异常，请联系管理员"),
// SYSTEM_RUNTIME_EXCEPTION("200002","系统运行异常，请联系管理员"),
// SYSTEM_AOP_ERROR("200003","AOP捕获全局异常"),
// ERROR_PARAM("300001","请求参数错误"),
// ERROR_DATA_NULL("400002", "数据不存在"),
// ERROR_DATA_NULL("400003", "用户不存在"),

// 生成 UUID
function getUUID() {
  let UUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  localStorage.setItem('deviceId', UUID)
  return UUID
}

import axios from 'axios'
import { Message } from 'element-ui';
import router from '../router/index'
import store from '../store/index'
let headers = axios.defaults.headers
headers['Content-Type'] = 'application/json;charset=utf-8'
headers['DeviceType'] = 3
let deviceId = localStorage.getItem('deviceId') || getUUID()
headers['DeviceId'] = deviceId

const options = {
  // 显示操作成功消息 默认不显示
  showSuccess: false,
  // 成功提醒 默认使用后端返回值
  successMsg: '',
  // 显示失败消息 默认显示
  showError: true,
  // 失败提醒 默认使用后端返回信息
  errorMsg: '',
  // 显示请求时loading模态框 默认显示
  showLoading: false,
  // loading提醒文字
  loadingMsg: '加载中',
  // 需要授权才能请求 默认放开
  auth: false,
  // ...
};

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,// 基本配置文件
  // 超时
  timeout: 20000,
})
// request拦截器，用于加token
service.interceptors.request.use(
  config => {
    config.custom = { ...options, ...config.custom }
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    let storeInfo = localStorage.getItem('storeInfo')
    storeInfo = JSON.parse(storeInfo)
    if (storeInfo && storeInfo.token) {
      config.headers['Authorization'] = storeInfo.token
    }

    // 请求参数值为null || undefined的不传入（params）
    for (let key in config.params) {
      if (config.params[key] === '' || config.params[key] === null || config.params[key] === undefined) {
        delete config.params[key]
      }
    }

    // 请求参数值为null || undefined的不传入（data）
    for (let key in config.data) {
      if (config.data[key] === '' || config.data[key] === null || config.data[key] === undefined) {
        delete config.data[key]
      }
    }

    return config
  },
  (error) => {
    console.log(error)
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.status
    if (code === 200) {
      // console.log(res)
      const { resultCode, errorMessage } = res.data

      // 埋点——非成功和token失效的场景直接返回
      if ((res.config.url == '/app-api/userAction/save') && (resultCode === '000000' || resultCode === '000001')) {
        return Promise.reject(res)
      }

      if (resultCode === '000000') {
        console.log('成功')
        return Promise.resolve(res.data)
      }
      // 未登录 || 登录状态失效
      else if (resultCode === '000001') {
        // 修改vuex的showLogin状态,显示登录组件
        store.dispatch("setUserInfo", null);
        store.dispatch("setToken", '');
        store.dispatch("setShowLogin", true);
        store.dispatch("setShowBindPhone", false);
        return Promise.resolve(res.data)
      }
      // 微信登录 且 未绑定手机号
      else if (resultCode === '100013') {
        // 修改vuex的showLogin状态,显示登录组件
        store.dispatch("setUserInfo", null);
        store.dispatch("setToken", '');
        store.dispatch("setShowLogin", false);
        store.dispatch("setShowBindPhone", true);
        return Promise.resolve(res.data)
      }
      // 微信登录 且 微信code失效
      else if (resultCode === '100012') {
        return Promise.resolve(res.data)
      }
      // 未实名认证
      else if (resultCode === '100015') {
        store.dispatch("setShowAuth", true);
        return Promise.resolve(res.data)
      }
      // 未实名认证（填写邀请人）
      else if (resultCode === '110005') {
        Message.error("请先进行实名认证");
        store.dispatch("setShowAuth", true);
        return Promise.resolve(res.data)
      }
      // 当前没有充值活动
      else if (resultCode === '900001') {
        return Promise.resolve(res.data)
      }
      // 充值活动——已经领取过
      else if (resultCode === '900002') {
        return Promise.resolve(res.data)
      }
      // 充值活动——活动已经超过30天，不能领取
      else if (resultCode === '900003') {
        return Promise.resolve(res.data)
      }
      // 春节活动——任务未解锁完，点击解锁次数不足 || 任务解锁完点击code
      else if (resultCode === '900004' || resultCode === '900005') {
        return Promise.resolve(res.data)
      }
      // 用户不存在
      else if (resultCode === '400003') {
        return Promise.resolve(res.data)
      }
      // 系统异常，请联系管理员
      else if (resultCode === '200001') {
        Message.error(errorMessage);
      }
      else {
        // 错误提示
        if (res.config.custom.showError) {
          Message.error(errorMessage);
        }
      }
      return Promise.reject(res.data)
    } else {
      return Promise.reject(res.data)
    }
  },
  (error) => {
    // 处理响应错误的情况
    // 心跳、埋点——报错直接返回
    if (error.config.url == '/app-api/user/isLogin' || error.config.url == '/app-api/userAction/save') {
      return Promise.reject(error)
    }

    if (error.response == undefined) {
      // 主动终止请求
      if (error && error.constructor.name == 'Cancel' && error.message == '请求被取消了') {
        console.log(error.message)
      } else {
        Message.error('请求超时')
      }
    } else if (error.response.status === 400) {
      let data = error.response.data
      Message.error(data.message);
    } else if (error.response.status === 403) {
      let data = error.response.data
      Message.error(data.message)
      // return Promise.reject(error);
    } else if (error.response.status === 401) {
      let data = error.response.data
      Message.error(data.message)
      // 判断当前路由，如果不是登录页面，则返回到登录页面
      const path = router.currentRoute.value.fullPath
      if (path !== '/login') {
        router.push('/login')
      }
    } else if (error.response.status === 409) {
      let data = error.response.data
      Message.error(data.message)
    }
    return Promise.reject(error)
  }
)

export default service
