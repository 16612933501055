import { render, staticRenderFns } from "./BindPhoneNumber.vue?vue&type=template&id=75821a4c&"
import script from "./BindPhoneNumber.vue?vue&type=script&lang=js&"
export * from "./BindPhoneNumber.vue?vue&type=script&lang=js&"
import style0 from "./BindPhoneNumber.vue?vue&type=style&index=0&id=75821a4c&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/gyf/Desktop/code/frontend/suixin-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('75821a4c')) {
      api.createRecord('75821a4c', component.options)
    } else {
      api.reload('75821a4c', component.options)
    }
    module.hot.accept("./BindPhoneNumber.vue?vue&type=template&id=75821a4c&", function () {
      api.rerender('75821a4c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BindPhoneNumber.vue"
export default component.exports