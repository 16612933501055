var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowAuth
    ? _c("div", { staticClass: "authCover" }, [
        _c("div", { staticClass: "authBox" }, [
          _c("i", {
            staticClass: "el-icon-close closebtn",
            on: { click: _vm.closeFun },
          }),
          _c("p", { staticClass: "dltit" }, [_vm._v("实名认证")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              "根据法律法规的要求，在实名认证的过程中，我们和提供认证服务的第三方机构需要您提供真实姓名、身份证号码。这些信息是个人敏感信息，平台会对信息进行严格保密，未经您明示授权，不会用作其他目的。 "
            ),
          ]),
          _c(
            "form",
            { ref: "form", staticClass: "formBox", attrs: { model: _vm.form } },
            [
              _c("div", { staticClass: "inputBox name" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.name,
                      expression: "form.name",
                    },
                  ],
                  attrs: { placeholder: "请输入真实姓名" },
                  domProps: { value: _vm.form.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "name", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "inputBox cardId" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.cardId,
                      expression: "form.cardId",
                    },
                  ],
                  attrs: { placeholder: "请输入身份证号", maxlength: "18" },
                  domProps: { value: _vm.form.cardId },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "cardId", $event.target.value)
                    },
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "loginBtn", on: { click: _vm.submit } },
                [_vm._v("立即认证")]
              ),
              _c(
                "el-checkbox",
                {
                  staticClass: "checkBox",
                  model: {
                    value: _vm.isCheckArgee,
                    callback: function ($$v) {
                      _vm.isCheckArgee = $$v
                    },
                    expression: "isCheckArgee",
                  },
                },
                [
                  _c("div", { staticClass: "agreement" }, [
                    _vm._v("我已同意 "),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.agreeHandle("agreeView")
                          },
                        },
                      },
                      [_vm._v("用户协议")]
                    ),
                    _vm._v("和 "),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.agreeHandle("privacyPolicy")
                          },
                        },
                      },
                      [_vm._v("隐私政策")]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }