<template>
    <div class='mengXinCover' v-if="isShowMengXin">
        <div class="mengXinCom">
            <i class="el-icon-close closebtn" @click="closeFun"></i>
            <img :src="mengXinInfo.imgUrl" alt="" class="imgUrl">
            <div class="nickName d-overflow">{{ mengXinInfo.nickname }}</div>
            <div class="btn" @click="privateChat">聊一聊（{{ count }}S）</div>
        </div>
    </div>
</template>
<script>
import {
    mengXinClick
} from "@/util/api/api.js"
import bus from '@/util/eventBus.js'
export default {
    name: 'mengXinPop',
    props: {
        isShowMengXin: {
            type: Boolean,
        },
        mengXinInfo: {
            type: Object
        }
    },
    data() {
        return {
            count: 60,
            timer: null,
        }
    },
    watch: {
        mengXinInfo() {
            console.log('初始化萌新驾到：', this.mengXinInfo)
            console.log('初始化计时器：', this.count)
            // 清除已存在的定时器数据
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
            let TIME_COUNT = 60;
            this.count = TIME_COUNT;
            this.timer = setInterval(() => {
                if (this.count > 1 && this.count <= TIME_COUNT) {
                    this.count--;
                } else {
                    this.closeFun()
                }
            }, 1000);
        }
    },
    created() {

    },
    methods: {
        closeFun() {
            clearInterval(this.timer);
            this.timer = null;
            this.$emit('closeMengXin', false)
        },

        // 打开私聊
        privateChat() {
            // this._mengXinClick()
            let data = {
                ...this.mengXinInfo,
                isMengXin: 1,// 萌新标识，用于推荐私聊状态更新
                targetId: this.mengXinInfo.id,// 会话对应的id
            }
            console.log('私聊对象信息：', data)
            this.closeFun()
            bus.$emit('watchPrivateChat', { isPrivateChat: true, chatUserInfo: data })
        },

        // // 推荐老板私聊状态更新
        // async _mengXinClick() {
        //     try {
        //         let params = {
        //             activeLiveId: this.mengXinInfo.activeLiveId
        //         }
        //         let res = await mengXinClick(params)
        //         if (res.resultCode === '000000') {
        //             console.log('推荐老板私聊状态更新成功')
        //         }
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },
    },
}
</script>

<style lang="less">
.mengXinCover {
    width: 100%;
    height: 100vh;
    background: rgba(3, 3, 3, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    .mengXinCom {
        width: 302px;
        height: 368px;
        background: url(../assets/imgs/bg_mengXin.png) no-repeat;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -151px;
        margin-top: -184px;
        animation: showDownPop ease .3s;

        .closebtn {
            color: #fff;
            cursor: pointer;
            width: 36px;
            height: 36px;
            font-size: 30px;
            line-height: 34px;
            text-align: center;
            border: 2px solid #fff;
            border-radius: 50%;
            position: absolute;
            top: 38px;
            right: -53px;
            box-sizing: border-box;
        }

        .imgUrl {
            width: 96px;
            height: 96px;
            background: #FFFFFF;
            border: 2px solid #3C8977;
            display: block;
            margin: 121px auto 0;
            border-radius: 50%;
        }

        .nickName {
            width: 175px;
            height: 28px;
            line-height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin: 18px auto 0;
            text-align: center;
        }

        .btn {
            height: 32px;
            width: 150px;
            text-align: center;
            line-height: 32px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            font-size: 14px;
            margin: 50px auto 0;
            border-radius: 20px;
            background: linear-gradient(90deg, #705DFF 0%, #B586F2 100%);
            cursor: pointer;
        }
    }
}
</style>
