
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from './element/index'
import 'element-ui/lib/theme-chalk/index.css';
import "swiper/swiper.min.css";
import './assets/css/element-variables.scss'
import './assets/css/index.css';
import './assets/css/fonts.css'; // 引入字体样式文件
import { vueBaberrage } from 'vue-baberrage'
import VueLazyLoad from 'vue-lazyload'; // 导入懒加载
// import howlerJs from './util/howler.core.min.js';// 导入可播放svga音效js库，根据不同浏览器自兼容
const loadimage = require('./assets/loading.png')
const errorimage = require('./assets/error.png')
Vue.use(VueLazyLoad, {
  error: errorimage,// 加载错误时的图片
  loading: loadimage,// 加载时的时图片
});

import { Message, Loading, MessageBox } from 'element-ui'
Vue.component(Message)
Vue.component(Loading)
Vue.component(MessageBox)
//挂载到Vue全局对象上
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading
Vue.prototype.$confirm = MessageBox.confirm

// Vue.use(howlerJs)
Vue.use(vueBaberrage)
Vue.use(ElementUI);

// 全局函数及变量
import Global from './Global';
Vue.use(Global);

//确认框
import confirmCom from '@/components/confirmCom/index';
console.log("confirmCom1111", confirmCom)
Vue.prototype.$confirmCom = confirmCom;

import * as RongIMLib from '@rongcloud/imlib-next'
// 正式appid：z3v5yqkbzgrw0
// 测试appid：lmxuhwagl3jsd
// 应用初始化以获取 RongIMLib 实例对象，请务必保证此过程只被执行一次
const appkey = process.env.NODE_ENV == "product" ? 'z3v5yqkbzgrw0' : 'lmxuhwagl3jsd'
RongIMLib.init({ appkey: appkey });
const IWStatusMsg = RongIMLib.registerMessageType('RC:IWStatusMsg', true, true, [], false)
const IWNormalMsg = RongIMLib.registerMessageType('RC:IWNormalMsg', true, true, [], false)
Vue.prototype.$IWStatusMsg = IWStatusMsg;
Vue.prototype.$IWNormalMsg = IWNormalMsg;

// 全局拦截器,在进入需要用户权限的页面前校验是否已经登录
router.beforeResolve((to, from, next) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
  const loginUser = store.state.userInfo;
  // 判断路由是否设置相应校验用户权限
  if (to.meta.requireAuth) {
    if (!loginUser) {
      // 没有登录，显示登录组件
      store.dispatch("setShowLogin", true);
      if (from.name == null) {
        //此时，是在页面没有加载，直接在地址栏输入链接，进入需要登录验证的页面
        next("/");
        return;
      }
      // 终止导航
      next(false);
      return;
    }
  }
  next();
});

// 相对时间过滤器,把时间戳转换成时间
// 格式: 2020-02-25 21:43:23
Vue.filter('dateFormat', (dataStr) => {
  var time = new Date(dataStr);
  function timeAdd0(str) {
    if (str < 10) {
      str = '0' + str;
    }
    return str;
  }
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return y + '-' + timeAdd0(m) + '-' + timeAdd0(d) + ' ' + timeAdd0(h) + ':' + timeAdd0(mm) + ':' + timeAdd0(s);
});


Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'product') {
  Vue.config.devtools = false;
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
