import Vue from 'vue';
import confirm from './index.vue';
console.log("confirm",confirm)
let confirmConstructor = Vue.extend(confirm);
let isShow = false // 控制弹窗同时仅可出现一个 
let _content = null
let theConfirm = function(content) {
	return new Promise((res, rej) => {
		console.log(_content)
		console.log(content,JSON.stringify(content)==JSON.stringify(_content))
		// 控制弹窗同时仅可出现一个 
		if(isShow && JSON.stringify(content)==JSON.stringify(_content)){
			return true
		}
		isShow = true
		_content = content
		let confirmDom = new confirmConstructor({
			el: document.createElement('div')
		});
		document.body.appendChild(confirmDom.$el);
		confirmDom.content = { ...confirmDom.content, ...content }; 
		confirmDom.confirmClick = function() {
			res();
			confirmDom.isConfirm = false;
			isShow = false
		};
		confirmDom.cancelcClick = function() {
			rej();
			confirmDom.isConfirm = false;
			isShow = false
		};
	});
};
export default theConfirm;

