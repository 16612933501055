var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "floatBox" }, [
    _c("img", {
      staticClass: "xr",
      attrs: { src: require("../assets/imgs/xr.png") },
    }),
    _c("div", { staticClass: "floatbg" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "quickRoom" },
        [
          _c("p", { staticClass: "floatimg" }),
          _c("span", [_vm._v("快速进房")]),
          _c("RoomList", { attrs: { quickRoomList: _vm.quickRoomList } }),
        ],
        1
      ),
      _c("div", [
        _c("p", {
          staticClass: "floatimg",
          on: { click: _vm.openPrivateChat },
        }),
        _c("span", [_vm._v("私信")]),
        _vm.messageUnreadCount && _vm.messageUnreadCount > 0
          ? _c("div", { staticClass: "count" }, [
              _vm._v(
                _vm._s(
                  _vm.messageUnreadCount && _vm.messageUnreadCount > 99
                    ? "99+"
                    : _vm.messageUnreadCount
                )
              ),
            ])
          : _vm._e(),
      ]),
      _vm._m(1),
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              return _vm.toTop()
            },
          },
        },
        [_c("p", { staticClass: "floatimg" }), _c("span", [_vm._v("回到顶部")])]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "floatimg" }),
      _c("span", [_vm._v("APP")]),
      _c("img", {
        staticClass: "floatCode",
        attrs: { src: require("../assets/imgs/icon_dlCode.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "serverBox" }, [
      _c("p", { staticClass: "floatimg" }),
      _c("span", [_vm._v("客服")]),
      _c("div", { staticClass: "serverPop d-flex a-center j-center" }, [
        _c("img", { attrs: { src: require("@/assets/imgs/QQ.png") } }),
        _c("div", { staticClass: "text" }, [_vm._v("客服QQ：1058127957")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }