var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.isShow
      ? _c("div", { staticClass: "confessionWallCardBox d-flex a-center" }, [
          _c(
            "div",
            { ref: "marqueeContainer", staticClass: "marquee-container" },
            [
              _c(
                "div",
                {
                  ref: "marquee",
                  staticClass: "marquee d-flex a-center",
                  class: { scrolling: _vm.shouldScroll },
                  style: _vm.marqueeStyle,
                  on: { animationend: _vm.handleAnimationEnd },
                },
                [
                  _c("div", { staticClass: "nickName" }, [
                    _vm._v(_vm._s(_vm.confessionInfo.nickName)),
                  ]),
                  _c("span", [_vm._v("向")]),
                  _c("div", { staticClass: "nickName targetNickname" }, [
                    _vm._v(_vm._s(_vm.confessionInfo.targetNickname)),
                  ]),
                  _c("span", [_vm._v("告白：")]),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.confessionInfo.content)),
                  ]),
                ]
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }