var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isWxLogin
    ? _c("div", { staticClass: "wxlogincover" }, [
        _c("div", { staticClass: "logincom" }, [
          _c("i", {
            staticClass: "el-icon-close closebtn",
            on: { click: _vm.closeFun },
          }),
          _c("p", { staticClass: "dltit" }, [_vm._v("手机号绑定")]),
          _c(
            "form",
            { ref: "form", staticClass: "loginf", attrs: { model: _vm.form } },
            [
              _c("div", { staticClass: "tel" }, [
                _c("p", [_vm._v("+86")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.tel,
                      expression: "form.tel",
                    },
                  ],
                  attrs: { placeholder: "请输入手机号", maxlength: "11" },
                  domProps: { value: _vm.form.tel },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "tel", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "yzcode" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.code,
                      expression: "form.code",
                    },
                  ],
                  attrs: { placeholder: "请输入验证码", maxlength: "6" },
                  domProps: { value: _vm.form.code },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "code", $event.target.value)
                    },
                  },
                }),
                _vm.isCode
                  ? _c("p", { on: { click: _vm.getCode } }, [
                      _vm._v("发送验证码"),
                    ])
                  : _c("p", [_vm._v("重新发送 " + _vm._s(_vm.count) + "s")]),
              ]),
              _c(
                "div",
                { staticClass: "loginBtn", on: { click: _vm.bindPhone } },
                [_vm._v("立即绑定")]
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }