const fixZero = (num, length) => {
    var str = "" + num;
    var len = str.length;
    var s = "";
    for (var i = length; i-- > len;) {
        s += "0";
    }
    return s + str;
}
/**
 * 日期格式转换：时间戳(毫秒) => 日期格式
 * sign=1（默认） xxxx.xx.xx
 *      2        xxxx年xx月xx日 xx:xx
 *      3        xxxx.xx.xx xx:xx
 *      4        xxxx-xx-xx
 *      5        xxxx-xx-xx xx:xx:xx
 *      6        xx-xx xx:xx
 */
export const converTimeStamp = (stamp, sign, isMillSecond = true) => {
    if (isMillSecond == false) {
        stamp = stamp * 1000;
    }
    let date = new Date(stamp);
    if (sign == 1 || sign == null || sign == '')
        return date.getFullYear() + "." + fixZero(date.getMonth() + 1, 2) + "." + fixZero(date.getDate(), 2);
    else if (sign == 2)
        return date.getFullYear() + "年" + fixZero(date.getMonth() + 1, 2) + "月" + fixZero(date.getDate(), 2) + "日 " + fixZero(date.getHours(), 2) + ":" + fixZero(date.getMinutes(), 2);
    else if (sign == 3)
        return date.getFullYear() + "." + fixZero(date.getMonth() + 1, 2) + "." + fixZero(date.getDate(), 2) + " " + fixZero(date.getHours(), 2) + ":" + fixZero(date.getMinutes(), 2);
    else if (sign == 4) {
        return date.getFullYear() + "-" + fixZero(date.getMonth() + 1, 2) + "-" + fixZero(date.getDate(), 2);
    } else if (sign == 5) {
        return date.getFullYear() + "-" + fixZero(date.getMonth() + 1, 2) + "-" + fixZero(date.getDate(), 2) + " " + fixZero(date.getHours(), 2) + ":" + fixZero(date.getMinutes(), 2) + ":" + fixZero(date.getSeconds(), 2);
    } else if (sign == 6) {
        return fixZero(date.getMonth() + 1, 2) + "-" + fixZero(date.getDate(), 2) + " " + fixZero(date.getHours(), 2) + ":" + fixZero(date.getMinutes(), 2);
    }
}

// 是否为当天
export const isToDay = (stamp, isMillSecond = true) => {
    if (isMillSecond == false) {
        stamp = stamp * 1000;
    }
    let date = new Date(stamp);
    const today = new Date();
    // 判断是否为当天
    if (today.getFullYear() === date.getFullYear() && (today.getMonth() + 1) === (date.getMonth() + 1) && today.getDate() === date.getDate()) {
        return true
    }
    return false
}


//获取当前日期
export const toDay = () => {
    let myDate = new Date();
    let nowY = myDate.getFullYear();
    let nowM = myDate.getMonth() + 1;
    let nowD = myDate.getDate();
    let endDate = nowY + "-" + (nowM < 10 ? "0" + nowM : nowM) + "-" + (nowD < 10 ? "0" + nowD : nowD); //当前日期
    return endDate;
}
// 获取当前日期的前x天日期
export const toEnd = (day) => {
    let myDate = new Date();
    let lw = new Date(myDate - 1000 * 60 * 60 * 24 * day); //最后一个数字day可改，day天的意思
    let lastY = lw.getFullYear();
    let lastM = lw.getMonth() + 1;
    let lastD = lw.getDate();
    let startData = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" + (lastD < 10 ? "0" + lastD : lastD); //三十天之前日期
    return startData;
}

/**
 * 
 * 聊天时间过滤
 * type: 1(会话列表展示) 昨天、前天、今天(10:30)及具体日期(07/28)
 * type: 2(聊天内容) 今天(10:30)及具体日期(07/28 10:30)
 */
export const messageTimeFilter = (type = 1, stamp) => {
    var stampNew = Date.parse(new Date()); //当前时间
    var timeDiffer = stampNew - stamp; //与当前时间误差

    let date = new Date(stamp);
    let today = new Date();
    let todayYear = today.getFullYear();
    let todayMonth = fixZero(today.getMonth() + 1, 2);
    let todayDate = fixZero(today.getDate(), 2);

    let targetYear = date.getFullYear();
    let targetMonth = fixZero(date.getMonth() + 1, 2);
    let targetDate = fixZero(date.getDate(), 2);
    let targetHour = fixZero(date.getHours(), 2);
    let targetMinutes = fixZero(date.getMinutes(), 2);
    // 同一天
    if (todayYear === targetYear && todayMonth === targetMonth && todayDate === targetDate) {
        return targetHour + ":" + targetMinutes;
    }
    // 同一年
    if (todayYear === targetYear) {
        if (type == 1) {
            if (timeDiffer > 3600000 && timeDiffer <= 86400000) {
                return '昨天'
            } else if (timeDiffer < 3600048) {
                return '前天'
            }
            return `${targetMonth}/${targetDate}`;
        }

        return `${targetMonth}/${targetDate} ${targetHour}:${targetMinutes}`;
    } else {
        return `${targetYear}/${targetMonth}/${targetDate} ${targetHour}:${targetMinutes}`;
    }
}

/**
 * 幸运榜时间展示
 * 当天：刚刚、x分钟前、x小时前，非当天展示具体 x月x日 12:31
 * dateTimeStamp是一个时间毫秒，注意时间戳是秒的形式，在这个毫秒的基础上除以1000，就是十位数的时间戳。13位数的都是时间毫秒。
 * @param {*} dateTimeStamp 
 * @returns 
 */
export const timeago = (dateTimeStamp) => {
    var minute = 1000 * 60;      //把分，时，天，周，半个月，一个月用毫秒表示
    var hour = minute * 60;
    // var day = hour * 24;
    // var week = day * 7;
    // var halfamonth = day * 15;
    // var month = day * 30;
    var now = new Date().getTime();   //获取当前时间毫秒
    console.log(now)
    var diffValue = now - dateTimeStamp;//时间差

    if (diffValue < 0) {
        return;
    }
    var minC = diffValue / minute;  //计算时间差的分，时，天，周，月
    var hourC = diffValue / hour;
    // var dayC = diffValue / day;
    // var weekC = diffValue / week;
    // var monthC = diffValue / month;
    let result = ''
    if (hourC >= 1 && hourC <= 23) {
        result = " " + parseInt(hourC) + "小时前"
    } else if (minC >= 1 && minC <= 59) {
        result = " " + parseInt(minC) + "分钟前"
    } else if (diffValue >= 0 && diffValue <= minute) {
        result = "刚刚"
    } else {
        var datetime = new Date();
        datetime.setTime(dateTimeStamp);
        var Nmonth = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
        var Ndate = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
        var Nhour = datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours();
        var Nminute = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
        result = `${Nmonth}月${Ndate}日 ${Nhour}:${Nminute}`
    }
    return result;
}


//防抖
export const debounce = (func, wait) => {
    var timeout;
    return function () {
        var context = this;
        var args = arguments;
        clearTimeout(timeout)
        timeout = setTimeout(function () {
            func.apply(context, args)
        }, wait);
    };
};

// 节流
export const throttle = (func, delay) => {
    var timer = null;
    return function () {
        var context = this;
        var args = arguments;
        if (!timer) {
            timer = setTimeout(function () {
                func.apply(context, args);
                timer = null;
            }, delay);
        }
    }
}

// 深拷贝
export const deepClone = (source) => {
    if (!source || typeof source !== 'object') {
        throw new Error('error arguments', 'shallowClone');
    }
    var targetObj = source.constructor === Array ? [] : {};
    for (var keys in source) {
        if (source.hasOwnProperty(keys)) {
            if (source[keys] && typeof source[keys] === 'object') {
                targetObj[keys] = source[keys].constructor === Array ? [] : {};
                targetObj[keys] = deepClone(source[keys]);
            } else {
                targetObj[keys] = source[keys];
            }
        }
    }
    return targetObj;
}

// 过滤关键字
export const replaceKeywords = (str, keywords)=> {
  let replacedStr = str;

  // 遍历关键字数组
  keywords.forEach(keyword => {
    // 根据关键字长度生成对应数量的 *
    const stars = '*'.repeat(keyword.value.length);

    // 使用正则表达式替换关键字的值为 *
    replacedStr = replacedStr.replace(new RegExp(keyword.value, 'gi'), stars);
  });

  return replacedStr;
}


// 存储数据
export const setWithExpiry = (key, value, ttl) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl * 24 * 60 * 60 * 1000 // 过期时间戳
  };
  localStorage.setItem(key, JSON.stringify(item));
}

// 获取数据
export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) { // 检查是否过期
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}