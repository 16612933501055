var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLogin
    ? _c("div", { staticClass: "logincover" }, [
        _c("div", { staticClass: "logincom" }, [
          _c("i", {
            staticClass: "el-icon-close closebtn",
            on: { click: _vm.closeFun },
          }),
          _c("p", { staticClass: "dltit" }, [_vm._v("登录")]),
          _c(
            "form",
            { ref: "form", staticClass: "loginf", attrs: { model: _vm.form } },
            [
              _c("div", { staticClass: "tel" }, [
                _c("p", [_vm._v("+86")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.tel,
                      expression: "form.tel",
                    },
                  ],
                  attrs: { placeholder: "请输入手机号", maxlength: "11" },
                  domProps: { value: _vm.form.tel },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "tel", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "yzcode" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.code,
                      expression: "form.code",
                    },
                  ],
                  attrs: { placeholder: "请输入验证码", maxlength: "6" },
                  domProps: { value: _vm.form.code },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "code", $event.target.value)
                    },
                  },
                }),
                _vm.isCode
                  ? _c("p", { on: { click: _vm.getCode } }, [
                      _vm._v("发送验证码"),
                    ])
                  : _c("p", [_vm._v("重新发送 " + _vm._s(_vm.count) + "s")]),
              ]),
              _c(
                "div",
                { staticClass: "loginBtn", on: { click: _vm.getUser } },
                [_vm._v("登录/注册")]
              ),
              _c(
                "el-checkbox",
                {
                  staticClass: "zd",
                  model: {
                    value: _vm.form.checked,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "checked", $$v)
                    },
                    expression: "form.checked",
                  },
                },
                [_vm._v("下次自动登录")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "loginwx" }, [
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.openWindow()
                  },
                },
              },
              [
                _c("p", [_vm._v("微信登录")]),
                _c("img", {
                  attrs: { src: require("../assets/imgs/wx2.png") },
                }),
              ]
            ),
            _c("p", { staticClass: "logints" }, [
              _vm._v("未注册手机验证后自动登录，登录或注册即代表同意 "),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.agreeHandle("agreeView")
                    },
                  },
                },
                [_vm._v("用户协议")]
              ),
              _vm._v("和 "),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.agreeHandle("privacyPolicy")
                    },
                  },
                },
                [_vm._v("隐私政策")]
              ),
            ]),
            _c("p", { staticClass: "logints" }, [
              _vm._v(
                "未满18周岁未成年人请勿自行注册，其注册、登录帐号及使用服务需征得监护人同意"
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }