var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isConfirm
    ? _c("div", { staticClass: "allcover" }, [
        _c("div", { staticClass: "confirmCom" }, [
          _c("p", { staticClass: "txt" }, [
            _vm._v(_vm._s(_vm.content.message)),
          ]),
          _c("div", { staticClass: "btnqrs d-flex a-center j-center" }, [
            _vm.content.isShowCancel
              ? _c(
                  "button",
                  { staticClass: "left", on: { click: _vm.cancelcClick } },
                  [_vm._v(_vm._s(_vm.content.cancelText))]
                )
              : _vm._e(),
            _c(
              "button",
              { staticClass: "right", on: { click: _vm.confirmClick } },
              [_vm._v(_vm._s(_vm.content.confirmText))]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }