var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.quickRoomList.length > 0
    ? _c("div", { staticClass: "roombox" }, [
        _c("div", { staticClass: "box" }, [
          _c(
            "ul",
            { staticClass: "roomtit d-flex a-center" },
            _vm._l(_vm.quickRoomList, function (item) {
              return _c("li", { key: item.id }, [_vm._v(_vm._s(item.name))])
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "roomlist" },
            _vm._l(_vm.quickRoomList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "roomli d-flex flex-column a-center",
                },
                [
                  _c(
                    "ul",
                    _vm._l(item.roomList, function (room) {
                      return _c(
                        "li",
                        {
                          key: room.id,
                          on: {
                            click: function ($event) {
                              return _vm.goRoom(room.id, item.id)
                            },
                          },
                        },
                        [_vm._v(_vm._s(room.name) + " ")]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "roomb",
                      on: {
                        click: function ($event) {
                          return _vm.moreHandle(item)
                        },
                      },
                    },
                    [_vm._v("全部" + _vm._s(item.name))]
                  ),
                ]
              )
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }