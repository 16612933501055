// 系统表情本地图片地址列表
export const localEmojiList = {
    's1_0': require('../assets/imgs/emoji/s1_0.png'),
    's1_1': require('../assets/imgs/emoji/s1_1.png'),
    's1_2': require('../assets/imgs/emoji/s1_2.png'),
    's1_3': require('../assets/imgs/emoji/s1_3.png'),
    's1_4': require('../assets/imgs/emoji/s1_4.png'),
    's1_5': require('../assets/imgs/emoji/s1_5.png'),
    's1_6': require('../assets/imgs/emoji/s1_6.png'),
    's1_7': require('../assets/imgs/emoji/s1_7.png'),
    's1_8': require('../assets/imgs/emoji/s1_8.png'),
    's1_9': require('../assets/imgs/emoji/s1_9.png'),
    's1_10': require('../assets/imgs/emoji/s1_10.png'),
    's1_11': require('../assets/imgs/emoji/s1_11.png'),
    's1_12': require('../assets/imgs/emoji/s1_12.png'),
    's1_13': require('../assets/imgs/emoji/s1_13.png'),
    's1_14': require('../assets/imgs/emoji/s1_14.png'),
    'dice_1': require('../assets/imgs/emoji/dice/dice_1.gif'),
    'dice_2': require('../assets/imgs/emoji/dice/dice_2.gif'),
    'dice_3': require('../assets/imgs/emoji/dice/dice_3.gif'),
    'dice_4': require('../assets/imgs/emoji/dice/dice_4.gif'),
    'dice_5': require('../assets/imgs/emoji/dice/dice_5.gif'),
    'dice_6': require('../assets/imgs/emoji/dice/dice_6.gif'),
    'scissors_1': require('../assets/imgs/emoji/scissors/scissors_1.gif'),
    'scissors_2': require('../assets/imgs/emoji/scissors/scissors_2.gif'),
    'scissors_3': require('../assets/imgs/emoji/scissors/scissors_3.gif'),
}