var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isPrivateChat
    ? _c("div", { staticClass: "privateChatCover" }, [
        _c("div", { staticClass: "flex-s-b privateChat" }, [
          _c("div", { staticClass: "privareL" }, [
            _c(
              "div",
              { staticClass: "searchCss" },
              [
                _c("el-input", {
                  attrs: {
                    placeholder: "搜索昵称",
                    "prefix-icon": "el-icon-search",
                  },
                  on: { input: _vm.searchInputHandle },
                  model: {
                    value: _vm.searchValue,
                    callback: function ($$v) {
                      _vm.searchValue = $$v
                    },
                    expression: "searchValue",
                  },
                }),
              ],
              1
            ),
            _vm.isShowFilterUserList
              ? _c(
                  "ul",
                  { staticClass: "overflow-auto userlist" },
                  _vm._l(_vm.filterUserList, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.targetId,
                        class: ["flex-s-b"],
                        on: {
                          click: function ($event) {
                            return _vm.userClick(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "userImgBox" }, [
                          _c("img", {
                            staticClass: "userimg",
                            attrs: { src: item.imgUrl },
                          }),
                          item.unreadMessageCount > 0
                            ? _c("span", { staticClass: "count" }, [
                                _vm._v(_vm._s(item.unreadMessageCount)),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "flex-s-b user-info" }, [
                          _c("p", { staticClass: "flex-f-c username" }, [
                            item.levelImg
                              ? _c("img", {
                                  staticClass: "levelIcon",
                                  attrs: { src: item.levelImg },
                                })
                              : _vm._e(),
                            _c("span", { staticClass: "text" }, [
                              _vm._v(_vm._s(item.nickname)),
                            ]),
                          ]),
                          _c("div", { staticClass: "chat-time" }, [
                            _vm._v(" " + _vm._s(item.date) + " "),
                          ]),
                          _c("p", { staticClass: "chat-txt" }, [
                            _vm._v(" " + _vm._s(item.valueText) + " "),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "ul",
                  { staticClass: "overflow-auto userlist" },
                  _vm._l(_vm.userList, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: item.targetId,
                        class: ["flex-s-b", { usersel: _vm.usersel == index }],
                        on: {
                          click: function ($event) {
                            return _vm.userClick(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "userImgBox" }, [
                          _c("img", {
                            staticClass: "userimg",
                            attrs: { src: item.imgUrl },
                          }),
                          item.unreadMessageCount > 0
                            ? _c("span", { staticClass: "count" }, [
                                _vm._v(_vm._s(item.unreadMessageCount)),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "flex-s-b user-info" }, [
                          _c("p", { staticClass: "flex-f-c username" }, [
                            item.levelImg
                              ? _c("img", {
                                  staticClass: "levelIcon",
                                  attrs: { src: item.levelImg },
                                })
                              : _vm._e(),
                            _c("span", { staticClass: "text" }, [
                              _vm._v(_vm._s(item.nickname)),
                            ]),
                          ]),
                          _c("div", { staticClass: "chat-time" }, [
                            _vm._v(" " + _vm._s(item.date) + " "),
                          ]),
                          _c("p", { staticClass: "chat-txt" }, [
                            _vm._v(" " + _vm._s(item.valueText) + " "),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
          ]),
          _c("div", { staticClass: "privareR" }, [
            _c("div", { staticClass: "flex-s-b h64" }, [
              _c("p", { staticClass: "flex-f-c username" }, [
                _vm.userList[_vm.usersel] && _vm.userList[_vm.usersel].levelImg
                  ? _c("img", {
                      staticClass: "levelIcon",
                      attrs: { src: _vm.userList[_vm.usersel].levelImg },
                    })
                  : _vm._e(),
                _vm.userList[_vm.usersel] && _vm.userList[_vm.usersel].nickname
                  ? _c("span", { staticClass: "text d-overflow" }, [
                      _vm._v(_vm._s(_vm.userList[_vm.usersel].nickname)),
                    ])
                  : _vm._e(),
              ]),
              _c("i", {
                staticClass: "el-icon-close closebtn",
                on: { click: _vm.closeHandle },
              }),
            ]),
            _c(
              "div",
              { ref: "chatScroll", staticClass: "overflow-auto chatLog" },
              [
                _vm._m(0),
                _vm.userRoom && _vm.userRoom.online
                  ? _c(
                      "div",
                      {
                        staticClass: "roomBox d-flex a-center j-center",
                        on: { click: _vm.goRoomHandle },
                      },
                      [
                        _vm._m(1),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            "我正在【" +
                              _vm._s(_vm.userRoom.name) +
                              "】，快来找我一起玩"
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.chatList, function (item, index) {
                  return _c("div", { key: index, staticClass: "chatItem" }, [
                    item.isShowTime
                      ? _c("div", { staticClass: "chatTime" }, [
                          _vm._v(" " + _vm._s(item.date) + " "),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { class: ["otherRecords", { myRecords: item.isMy }] },
                      [
                        _c("img", {
                          staticClass: "userimg",
                          attrs: {
                            src: item.isMy ? _vm.rightImgUrl : _vm.leftImgUrl,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.goUserHome(item)
                            },
                          },
                        }),
                        _c(
                          "div",
                          {
                            class: [
                              "messageBox",
                              { imgMessageBox: item.type != 1 },
                            ],
                          },
                          [
                            item.action == "im"
                              ? _c(
                                  "div",
                                  { staticClass: "imBox" },
                                  [
                                    item.type == 1
                                      ? _c("div", { staticClass: "text" }, [
                                          _vm._v(_vm._s(item.value)),
                                        ])
                                      : _vm._e(),
                                    item.type == 2
                                      ? _c("el-image", {
                                          staticClass: "imImg",
                                          attrs: {
                                            src: item.msgUrl,
                                            "preview-src-list": [item.msgUrl],
                                          },
                                          on: { load: _vm.imgIsLoaded },
                                        })
                                      : _vm._e(),
                                    item.type == 3
                                      ? _c("img", {
                                          staticClass:
                                            "emoPackImg emoPackImgSys",
                                          attrs: {
                                            src: `${
                                              _vm.localEmojiList[item.msgUrl]
                                            }?${item.time}`,
                                          },
                                          on: {
                                            contextmenu: function ($event) {
                                              $event.preventDefault()
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    item.type == 4
                                      ? _c(
                                          "div",
                                          { staticClass: "emojiPackBox" },
                                          [
                                            _c("el-image", {
                                              staticClass: "emoPackImg",
                                              attrs: {
                                                src: item.msgUrl,
                                                "preview-src-list": [
                                                  item.msgUrl,
                                                ],
                                              },
                                              on: {
                                                contextmenu: [
                                                  function ($event) {
                                                    $event.preventDefault()
                                                  },
                                                  function ($event) {
                                                    return ((e) =>
                                                      _vm.emojiRightHandle(
                                                        e,
                                                        index,
                                                        item
                                                      )).apply(null, arguments)
                                                  },
                                                ],
                                                load: _vm.imgIsLoaded,
                                              },
                                            }),
                                            _vm.emojiRightHandleInfo.index ==
                                              index && _vm.isShowAddEmoji
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "addEmoji",
                                                    on: {
                                                      click: _vm.addEmojiHandle,
                                                    },
                                                  },
                                                  [_vm._v("添加表情")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : item.action == "gift"
                              ? _c(
                                  "div",
                                  { staticClass: "gift d-flex a-center" },
                                  [
                                    _c("span", { staticClass: "text" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.isMy
                                            ? "你送给对方"
                                            : "对方送给你"
                                        ) +
                                          " " +
                                          _vm._s(item.gnum) +
                                          "个"
                                      ),
                                    ]),
                                    _c("img", {
                                      staticClass: "giftIcon",
                                      attrs: { src: item.gurl, alt: "" },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ])
                }),
              ],
              2
            ),
            _c("div", { staticClass: "flex-s-b chatL privareSend" }, [
              _c(
                "div",
                {
                  staticClass: "gift giveGiftBtn",
                  on: {
                    click: function ($event) {
                      return _vm.openGiftPop()
                    },
                  },
                },
                [_vm._v("赠送礼物")]
              ),
              _c("div", { staticClass: "flex-f-r chatInput a-center j-sb" }, [
                _c("i", {
                  staticClass: "emojiIcon",
                  on: { click: _vm.openEmojiCom },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.messageValue,
                      expression: "messageValue",
                    },
                  ],
                  attrs: { placeholder: "说点什么..." },
                  domProps: { value: _vm.messageValue },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.sendMessageHandle(1)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.messageValue = $event.target.value
                    },
                  },
                }),
                _c("div", { staticClass: "right d-flex a-center" }, [
                  _c("img", {
                    staticClass: "iconUploadImg",
                    attrs: {
                      src: require("../assets/imgs/icon_uploadImg.png"),
                      alt: "",
                    },
                    on: { click: _vm.chooseImage },
                  }),
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.sendMessageHandle(1)
                        },
                      },
                    },
                    [_vm._v("发送")]
                  ),
                ]),
              ]),
            ]),
            _c("input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              ref: "fileRef",
              attrs: {
                type: "file",
                accept: "image/png, image/jpeg,, image/gif",
              },
              on: { change: _vm.getImageInfo },
            }),
          ]),
          _vm.isGiftModal
            ? _c(
                "div",
                {
                  staticClass: "giftModal d-flex flex-column",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex-s-b" }, [
                    _c(
                      "ul",
                      { staticClass: "d-flex a-center giftType" },
                      _vm._l(_vm.giftType, function (item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            class: { giftsel: _vm.typesel == index },
                            on: {
                              click: function ($event) {
                                return _vm.typeClick(item, index)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.label))]
                        )
                      }),
                      0
                    ),
                    _vm.typesel == 1
                      ? _c(
                          "div",
                          { staticClass: "bagDiamondNum d-flex a-center" },
                          [
                            _c("img", {
                              staticClass: "icon_diamond",
                              attrs: { src: require("../assets/imgs/z.png") },
                            }),
                            _c("span", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.bagDiamondNum)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "giftcont" }, [
                    _vm.giftList.length > 0 && !_vm.isLoadingGift
                      ? _c("div", [
                          _vm.typesel == 0
                            ? _c(
                                "ul",
                                {
                                  staticClass: "overflow-auto d-flex flex-wrap",
                                },
                                _vm._l(_vm.giftList, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      class: { sel: _vm.giftsel == index },
                                      on: {
                                        click: function ($event) {
                                          return _vm.giftClick(item, index)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "w64" }, [
                                        _c("img", {
                                          attrs: { src: item.iconUrl },
                                        }),
                                      ]),
                                      _c("p", { staticClass: "giftname" }, [
                                        _vm._v(_vm._s(item.name)),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "num d-flex a-center j-center",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("../assets/imgs/z.png"),
                                            },
                                          }),
                                          _vm._v(
                                            " " + _vm._s(item.diamond) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm.typesel == 1
                            ? _c(
                                "ul",
                                {
                                  staticClass: "overflow-auto d-flex flex-wrap",
                                },
                                _vm._l(_vm.giftList, function (item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      class: { sel: _vm.giftsel == index },
                                      on: {
                                        click: function ($event) {
                                          return _vm.giftClick(item, index)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "w64" }, [
                                        _c("img", {
                                          attrs: { src: item.giftIconUrl },
                                        }),
                                        item.num
                                          ? _c("p", { staticClass: "cs" }, [
                                              _vm._v(_vm._s(item.num)),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _c("p", { staticClass: "giftname" }, [
                                        _vm._v(_vm._s(item.giftName)),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "num d-flex a-center j-center",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("../assets/imgs/z.png"),
                                            },
                                          }),
                                          _vm._v(
                                            " " + _vm._s(item.diamond) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                        ])
                      : !_vm.isLoadingGift
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "noData d-flex flex-column a-center j-center",
                          },
                          [
                            _c("img", {
                              staticClass: "iconNoData",
                              attrs: {
                                src: require("@/assets/imgs/setup/icon_noData.png"),
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "text" }, [
                              _vm._v("暂无礼物"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "flex-s-b sgiftBottom" }, [
                    _c("div", { staticClass: "czCont" }, [
                      _c("img", {
                        attrs: { src: require("../assets/imgs/z.png") },
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.balance.diamond || 0))]),
                      _c("p", { on: { click: _vm.goRecharge } }, [
                        _vm._v("去充值"),
                        _c("i", { staticClass: "el-icon-arrow-right" }),
                      ]),
                    ]),
                    !(
                      _vm.typesel == 1 &&
                      _vm.giftList &&
                      _vm.giftList.length > 0 &&
                      _vm.giftsel != null &&
                      _vm.giftList[_vm.giftsel].type == 2
                    )
                      ? _c(
                          "div",
                          { staticClass: "flex-s-b zsCont" },
                          [
                            _c(
                              "p",
                              { staticClass: "giveNumBox d-flex a-center" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.giveGiftNum,
                                      expression: "giveGiftNum",
                                    },
                                  ],
                                  staticClass: "num",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.giveGiftNum },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.giveGiftNum = $event.target.value
                                      },
                                      _vm.giveNumHandle,
                                    ],
                                  },
                                }),
                                _c("i", {
                                  class: [
                                    "fastIcon",
                                    {
                                      "el-icon-arrow-up":
                                        !_vm.isShowGiveFastNumPop,
                                    },
                                    {
                                      "el-icon-arrow-down":
                                        _vm.isShowGiveFastNumPop,
                                    },
                                  ],
                                  on: { click: _vm.giveFastNumHandle },
                                }),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.isLoadingBtn,
                                },
                                on: { click: _vm.giveHandle },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.typesel != 3 ? "赠送" : "抽取")
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm.isShowGiveFastNumPop
                    ? _c(
                        "div",
                        { staticClass: "giveFastNumBox d-flex a-center j-sb" },
                        _vm._l(_vm.giveNumTabList, function (item, index) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "itemBox" },
                            [
                              !(item.value == "all" && _vm.typesel != 1)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "num",
                                      class: {
                                        active: index == _vm.giveNumTabSel,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.giveNumTabHandle(index)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.text))]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.isShowNamingPop
            ? _c("div", { staticClass: "allcover modalcss" }, [
                _c("div", { staticClass: "namingPop" }, [
                  _c("i", {
                    staticClass: "el-icon-close closebtn",
                    on: {
                      click: function ($event) {
                        _vm.isShowNamingPop = !_vm.isShowNamingPop
                      },
                    },
                  }),
                  _c("div", { staticClass: "title" }, [_vm._v("赠送冠名礼物")]),
                  _c("div", { staticClass: "line" }),
                  _c(
                    "div",
                    { staticClass: "taskcont" },
                    [
                      _c("el-input", {
                        staticClass: "namingInput",
                        attrs: {
                          maxLength: 4,
                          placeholder: "请输入冠名（4个字内）",
                        },
                        model: {
                          value: _vm.namingValue,
                          callback: function ($$v) {
                            _vm.namingValue = $$v
                          },
                          expression: "namingValue",
                        },
                      }),
                      _c("div", { staticClass: "desc" }, [
                        _vm._v(
                          "冠名有效期" +
                            _vm._s(_vm.giftList[_vm.giftsel].days) +
                            "天后自动失效"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "btnBox d-flex a-center j-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          on: { click: _vm.giveNamingGift },
                        },
                        [_vm._v("确定")]
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowEmoji,
                  expression: "isShowEmoji",
                },
              ],
              staticClass: "emojiBox",
            },
            [
              _c("emojiCom", {
                ref: "emojiRef",
                attrs: { from: "privateChat" },
                on: { sendSuccessHandle: _vm.emojiSendSuccessHandle },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titleDesc" }, [
      _vm._v("一切以"),
      _c("span", [_vm._v("投资、理财、炒币、炒股")]),
      _vm._v("等赚钱理由，要求加微信或下载其他软件，均为诈骗，请立即举报"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "voice-playing" }, [
      _c("div", { staticClass: "play1" }),
      _c("div", { staticClass: "play2" }),
      _c("div", { staticClass: "play3" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }